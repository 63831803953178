import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { openNewPage, copyText, AVATARS_ARR } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Account() {
  const toastDialogRef = useRef(null);

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    showToast("ID Copied!", 2500, 0, 1);
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <div className="zoomAnimView">
          <div
            className={[
              styles.rowView,
              styles.alignFlexStart,
              styles.accountView,
            ].join(" ")}
          >
            <div
              className="h-w-70 ovf-hidden br-50"
              onClick={() => navigate("/updateaccount", { replace: false })}
            >
              {getStorage("avatarId") ? (
                <img
                  src={
                    AVATARS_ARR[Number(getStorage("avatarId")) - 1]["avatarURL"]
                  }
                  className="h-w-70"
                  alt="icon"
                />
              ) : (
                ""
              )}
            </div>

            <div className="col-view mg-l-15">
              <Link className="row-view txt-deco-n" to={"/changename"}>
                <div className="cl-white ft-wgt-500 ft-sz-1p25rem">
                  {getStorage("uname")}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="200"
                  height="200"
                  viewBox="0 0 200 200"
                  className="w-1p2-rem h-1p2-rem mg-l-10"
                  fill="#FFFFFF"
                >
                  <path d="M135.554 41.667v12.5h-89.72v91.666h99.999V100h12.5v45.833a12.5 12.5 0 0 1-12.5 12.5h-100a12.5 12.5 0 0 1-12.5-12.5V54.167a12.5 12.5 0 0 1 12.5-12.5h89.721Zm26.709 4.733 8.808 8.867-67.133 66.658.025.025-9.1.308.258-9.233.017.021L162.262 46.4Z"></path>
                </svg>
              </Link>

              <div
                className={[general.widthFitContent, general.colorWhite, general.fontS14Px, general.pd0px5px, general.borderRadius, general.bgCustomColor7].join(" ")}
                onClick={() => copyMyId()}
              >
                UID &nbsp; | &nbsp; {getStorage("uid")}
                <img
                  src={require("../icons/copy_icon.png")}
                  className="h-10-p mg-l-15"
                  alt="icon"
                />
              </div>
              <div className="cl-white ft-sz-14 mg-t-5">
                Last Active: {getStorage("accountLastActive")}
              </div>
            </div>
          </div>

          <div
            className={[styles.accountOptionsView, styles.colView].join(" ")}
          >
            <div className="w-100 z-i-1">
              <div
                className={[styles.colView, styles.accountBalanceView].join(
                  " "
                )}
              >
                <span className="cl-white">Account Balance:</span>
                <span className="cl-white ft-sz-23">
                  ₹{Number(getStorage("balance")).toLocaleString()}
                </span>

                <div className="line-hv-grey mg-t-10"></div>

                <div
                  className={[styles.rowView, styles.spaceBetween].join(" ")}
                >
                  <Link
                    className="txt-deco-n col-view v-center"
                    to={"/recharge"}
                  >
                    <img
                      src={require("../media/icons/deposit_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-white ft-sz-15">Deposit</span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view v-center"
                    to={"/withdraw"}
                  >
                    <img
                      src={require("../media/icons/withdrawl_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-white ft-sz-15">Withdraw</span>
                  </Link>

                  <div
                    className="col-view v-center"
                    onClick={() => openNewPage(getStorage("appDownloadURL"))}
                  >
                    <img
                      src={require("../media/icons/download_icon.png")}
                      className="h-w-28"
                      alt="icon"
                    />
                    <span className="cl-white ft-sz-15">Download</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={[styles.accountShortCutOptionsView]}>
              <Link className={[styles.rowView]} to={"/rechargerecords"}>
                <img
                  src={require("../media/icons/deposit_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[styles.fontS17Px]}>Deposit</span>
                  <span
                    className={[styles.fontS12Px, styles.colorLightWhite].join(
                      " "
                    )}
                  >
                    My Deposit History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/withdrawrecords"}
              >
                <img
                  src={require("../media/icons/withdrawl_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[styles.fontS17Px]}>Withdraw</span>
                  <span
                    className={[styles.fontS12Px, styles.colorLightWhite].join(
                      " "
                    )}
                  >
                    My Withdraw History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/myorders"}
              >
                <img
                  src={require("../media/icons/bet_history_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[styles.fontS17Px]}>Bet</span>
                  <span
                    className={[styles.fontS12Px, styles.colorLightWhite].join(
                      " "
                    )}
                  >
                    My Bet History
                  </span>
                </div>
              </Link>

              <Link
                className="txt-deco-n row-view pd-15-10 br-10 bg-white"
                to={"/transactions"}
              >
                <img
                  src={require("../media/icons/transactions_icon.png")}
                  alt="icon"
                />
                <div className="col-view mg-l-5">
                  <span className={[styles.fontS17Px]}>Transactions</span>
                  <span
                    className={[styles.fontS12Px, styles.colorLightWhite].join(
                      " "
                    )}
                  >
                    My Transactions History
                  </span>
                </div>
              </Link>
            </div>

            <div
              className={[styles.accountMoreOptionsView, general.mgB100px].join(
                " "
              )}
            >
              <Link
  className={[styles.rowView, styles.spaceBetween].join(" ")}
  onClick={() => openNewPage("https://t.me/Mezzer_xyz")}
>
  <div className={[styles.viewCenter]}>
    <img
      src={require("../media/icons/live_support_icon.png")}
      alt="icon"
    />
    <span>Live Support</span>
  </div>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    className="tranf-rotate-180"
    fill="#FFFFFF"
  >
    <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
  </svg>
</Link>


              <Link
                className={[
                  styles.rowView,
                  styles.spaceBetween,
                  Number(getStorage("accountLevel")) <= 2
                    ? styles.hideView
                    : "",
                ].join(" ")}
                to={"/agentreports"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/agent_transactions_icon.png")}
                    alt="icon"
                  />
                  <span>Agent Report</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#FFFFFF"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/download_icon.png")}
                    alt="icon"
                  />
                  <span>Download</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#FFFFFF"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/gift_icon.png")}
                    alt="icon"
                  />
                  <span>Redeem Gift</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#FFFFFF"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/redeemgiftcard"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/statistics_icon.png")}
                    alt="icon"
                  />
                  <span>Game Statistics</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#FFFFFF"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
                className={[styles.rowView, styles.spaceBetween].join(" ")}
                to={"/devicemanager"}
              >
                <div className={[styles.viewCenter]}>
                  <img
                    src={require("../media/icons/device_manager_icon.png")}
                    alt="icon"
                  />
                  <span>Device Manager</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  className="tranf-rotate-180"
                  fill="#FFFFFF"
                >
                  <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
                </svg>
              </Link>

              <Link
  className={[styles.rowView, styles.spaceBetween].join(" ")}
  onClick={() => openNewPage("https://t.me/+neg33CJcZFEwMDNh")}
>
  <div className={[styles.viewCenter]}>
    <img
      src={require("../media/icons/notification_icon.png")}
      alt="icon"
    />
    <span>Telegram</span>
  </div>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    className="tranf-rotate-180"
    fill="#FFFFFF"
  >
    <path d="M10.307 14.5 3.807 8l6.5-6.5 1.886 1.886L7.58 8l4.614 4.614-1.886 1.886Z"></path>
  </svg>
</Link>


              <div className={[general.pd10px]}>
                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT30px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => signOutAccount()}
                >
                  <div className="v-center">
                    <span className="cl-white mg-l-10">Sign Out</span>
                  </div>

                  <img
                    className={[general.heightWidth15px, general.mgL10px].join(
                      " "
                    )}
                    src={require("../media/icons/angle_right_white_icon.png")}
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="account" />
      </div>
    </div>
  );
}

export default Account;
