import React, { useState, useEffect } from 'react';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog/dialog';
import ToastDialog from "../dialogs/ToastDialog/toast";
import '../../MainStyle.css';
import general from '../css/General.module.css';
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import { useNavigate } from 'react-router-dom';
const RandomBonus = () => {
  const [pageConst, setPageConst] = useState({
    pageTitle: ' Youtube Creative Studio',
    isLoadingShow: false,
    recordList: [],
  });
  const [showLoading, setShowLoading] = useState(false);


  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 2,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    // mode: 1 / success
    // mode: 2 / warning

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogShow: data };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastDialogMode: mode };
    });

    setToastDialogConst((previousState) => {
      return { ...previousState, toastMessage: msg };
    });
  };



  const navigate = useNavigate();

  const topBarClickAction = () => {
  };

  return (
    <div className={`${general.viewCenter} ${general.appBackground}`}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(' ')}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <div className="game-manage-view col-view mg-t-45">
          <div className="col-view br-right-t br-left-t mg-b-15">
            <div className="col-view min-h">
              <div className="row-view ovf-scrl-x bg-drk-yellow cl-l-grey hide-sb pd-0-10">
              <img
                  src={require("../media/117.png")}
                  className="w-100"
                  alt=" Youtube Creative Studio"
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default RandomBonus;