import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TopBar from "../other-components/TopBar";
import LoadingDialog from "../dialogs/LoadingDialog/dialog";
import { API_ACCESS_URL, generateAuthToken } from "../modals/Constants";
import { deleteStorage, getStorage } from "../modals/Storage";
import general from "../css/General.module.css";

function AllMembers() {
  const navigate = useNavigate();
  const [showLoading, setShowLoading] = useState(false);
  const [teamReport, setTeamReport] = useState({
    totalRegisteredSub: 0,
    totalDepositNumberSub: 0,
    totalDepositAmountSub: "0.00",
    totalFirstDepositSub: 0,
    totalRegisteredTSub: 0,
    totalDepositNumberTSub: 0,
    totalDepositAmountTSub: "0.00",
    totalFirstDepositTSub: 0,
    totalTodaysIncome: "0.00",
  });

  const [pageConst, setConstants] = useState({
    pageTitle: "All Members",
    isLoadingShow: false,
    recordList: [],
  });

  const topBarClickAction = (data) => {
    if (data === "multiBtn1") {
      navigate("/withdraw", { replace: false });
    }
  };

  const updateLoadingStatus = (data) => {
    setShowLoading(data);
  };

  const updateTeamReport = (data) => {
    const report = {
      totalRegisteredSub: data.total_registered_d_sub || 0,
      totalDepositNumberSub: data.total_deposit_number_d_sub || 0,
      totalDepositAmountSub: data.total_deposit_amount_d_sub || "0.00",
      totalFirstDepositSub: data.total_num_firstdeposit_d_sub || 0,
      totalRegisteredTSub: data.total_registered_t_sub || 0,
      totalDepositNumberTSub: data.total_deposit_number_t_sub || 0,
      totalDepositAmountTSub: data.total_deposit_amount_t_sub || "0.00",
      totalFirstDepositTSub: data.total_num_firstdeposit_t_sub || 0,
      totalTodaysIncome: data.total_todays_income || "0.00",
    };
    setTeamReport(report);
  };

  function getAllMembers() {
    const requestAPI = async (url) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-commission-records2",
            AuthToken: generateAuthToken(Number(getStorage("uid"))),
          },
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code === "success") {
          updateTeamReport(data);
        }
      } catch (error) {
        updateLoadingStatus(false);
        console.error("Error fetching data:", error);
      }
    };

    updateLoadingStatus(true);
    requestAPI(
      API_ACCESS_URL + "?USER_ID=" + getStorage("uid") + "&PAGE_NUM=1"
    );
  }

  useEffect(() => {
    if (!getStorage("uid")) {
      if (deleteStorage()) {
        navigate("/login", { replace: true });
      }
    } else {
      getAllMembers();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <TopBar
          intentData={pageConst}
          multiBtn={true}
          multiBtn1=""
          multiBtn2=""
          updateState={topBarClickAction}
        />
        <LoadingDialog intentData={showLoading} />

        
        <div className="pd-15 mg-t--130">
          <div className="w-100 row-view sb-view a-flex-start br-10 bg-custom-lightblue ovf-hidden">
            <div className="w-100 col-view">
              <div className="w-100 row-view pd-10">
                <img
                  className="h-w-22"
                  src={require("../media/icons/direct_subordinate_icon.png")}
                  alt="icon"
                />
                <span className="cl-white ft-sz-14 mg-l-10">Direct Subordinates</span>
              </div>

              <div className="w-100 col-view a-center pd-10">
                <span className="cl-white ft-sz-13">{pageConst.totalRegisteredDs}</span>
                <span className="cl-white ft-sz-12">number of register</span>

                <span className="cl-green ft-sz-13 mg-t-10">{pageConst.totalDepositNumberDs}</span>
                <span className="cl-white ft-sz-12">Deposit number</span>

                <span className="cl-golden ft-sz-13 mg-t-10">{pageConst.totalDepositAmountDs}</span>
                <span className="cl-white ft-sz-12">Deposit amount</span>

                <span className="cl-white ft-sz-13 mg-t-10">{pageConst.totalFirstDepositNumberDs}</span>
                <span className="cl-l-white txt-a-center ft-sz-12">
                  Number of people making<br></br>first deposit
                </span>
              </div>
            </div>

            <div className="w-100 col-view">
              <div className="w-100 row-view pd-10">
                <img
                  className="h-w-22"
                  src={require("../media/icons/team_subordinate_icon.png")}
                  alt="icon"
                />
                <span className="cl-white ft-sz-14 mg-l-10">Team Subordinates</span>
              </div>
      </div>
    </div>
    </div>
    </div>
    </div>

  );
}

export default AllMembers;
