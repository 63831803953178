import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ToastDialog from "../dialogs/ToastDialog/toast";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import { copyText } from "../modals/Constants";
import { getStorage, deleteStorage } from "../modals/Storage";

import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Activity() {
  const toastDialogRef = useRef(null);
  const [pageConst, setGameData] = useState({
    pageTitle: "Activity",
  });

  const navigate = useNavigate();
  const signOutAccount = () => {
    if (deleteStorage()) {
      navigate("/login", { replace: true });
    }
  };

  // toast component
  const onToastChange = (data, msg) => { };

  const showToast = (message, duration, delay, mode) => {
    toastDialogRef.current.openDialog(message, duration, delay, mode);
  };

  const copyMyId = () => {
    copyText(getStorage("uid"));
    showToast("ID Copied!", 2500, 0, 1);
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      signOutAccount();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />

        <div className={[general.posRelative].join(" ")}>
          <div
            className={[
              general.colView,
              general.alignCenter,
              general.pd10px15px,
              general.bgCustomColor1,
            ].join(" ")}
          >
            <div className="col-view">
              <p className="ft-sz-25 cl-white">Activity</p>
              <p className="ft-sz-14 cl-white">
                Please remember to follow the event page.
              </p>
              <p className="ft-sz-14 cl-white">
                We will launch user feedback activities from time to time.
              </p>
            </div>
          </div>

          <div
            className={[general.colView, styles.activityContentsView].join(" ")}
          >
            <div className="g-v-2">
              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgCustomColor6,
                ].join(" ")}
                to={"/redeemgiftcard"}
              >
                <img
                  src={require("../icons/othericons/1.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorWhite].join(
                      " "
                    )}
                  >
                    Gifts
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorWhite].join(
                      " "
                    )}
                  >
                    Enter the redemption code to receive gift rewards
                  </span>
                </div>
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.colView,
                  general.borderRadius10px,
                  general.bgCustomColor6,
                ].join(" ")}
                to={"/dailycheckin"}
              >
                <img
                  src={require("../icons/othericons/2.png")}
                  className="w-100"
                  alt="icon"
                />
                <div className="col-view pd-15-10 mg-t-10">
                  <span
                    className={[general.fontS18Px, general.colorWhite].join(
                      " "
                    )}
                  >
                    Attendance bonus
                  </span>
                  <span
                    className={[general.fontS12Px, general.colorWhite].join(
                      " "
                    )}
                  >
                    The more consecutive days you sign in, the higher the reward
                    will be.
                  </span>
                </div>
              </Link>
            </div>

            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT15px,
              ].join(" ")}
            >
              
              <img
    src="https://i.postimg.cc/2S8V24yY/1111-1.png"
    alt="image"
    style={{ cursor: "pointer" }}
    onClick={() => {
      window.location.href = "/FirstDepositBonus"; // Update this path if needed
    }}
  />
      <span>💸 Win Streak Bonus💸</span>
            </div>

            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
            >
              
              <img
    src="https://i.postimg.cc/5tQ9RpMh/1112.png"
    alt="image"
    style={{ cursor: "pointer" }}
    onClick={() => {
      window.location.href = "/Lucky"; // Update this path if needed
    }}
  />
              <span>💰Lucky 10 Days Of Interest💰</span>
            </div>

            <div
              className={[
                styles.activityListView,
                general.bgCustomColor4,
                general.mgT10px,
              ].join(" ")}
            >
               <img
    src="https://i.postimg.cc/5tDBhPQL/1114.png"
    alt="image"
    style={{ cursor: "pointer" }}
    onClick={() => {
      window.location.href = "/Youtube"; // Update this path if needed
    }}
  />

              <span>❗️ Youtube Creative Studio❗️</span>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="activity" />
      </div>
    </div>
  );
}

export default Activity;
