import React, { useEffect, useState } from "react";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import LoadingDialog from "../dialogs/LoadingDialog";
import "../../MainStyle.css";
import { Link, useNavigate } from "react-router-dom";
import { getStorage } from "../modals/Storage";
import { openNewPage } from "../modals/Constants";
import ToastDialog from "../dialogs/ToastDialog/toast";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";

function Deposit() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
    pageTitle: "Deposit",
    isLoadingShow: false,
    inRechargeAmnt: "",
    recordList: [],
  });

  const [toastDialogConst, setToastDialogConst] = useState({
    toastDialogShow: false,
    toastDialogMode: 1,
    toastMessage: "",
  });

  const updateToastDialogState = (data, mode, msg) => {
    setToastDialogConst((previousState) => ({
      ...previousState,
      toastDialogShow: data,
      toastDialogMode: mode,
      toastMessage: msg,
    }));
  };

  const [activeItem, setActiveItem] = useState('okpay');
  const [selectedGateway, setSelectedGateway] = useState('okpay');

  const items = [
    { id: 'okpay', type: 'upi5', name: 'Ok Pay', img: require("../icons/payments/upi-icon.png") },
    { id: 'cryptopay', type: 'crypto', name: 'Crypto Pay', img: require("../icons/logocr.png") },
  ];

  const handleOnClickChannel = (item) => {
    setActiveItem(item.id);
    setSelectedGateway(item.id);
  };

  const onInputValChange = (source, data) => {
    if (source === "rechargeamount") {
      setConstants((previousState) => ({
        ...previousState,
        inRechargeAmnt: data,
      }));
    }
  };

  const rechargeNow = () => {
    if (
      getStorage("paymentURL") &&
      Number(pageConst.inRechargeAmnt) >= getStorage("minrecharge")
    ) {
      openNewPage(
        getStorage("paymentURL") +
          "?am=" +
          pageConst.inRechargeAmnt +
          "&crudentials=" +
          getStorage("uid")
      );
    }
  };

  const rechargeNowFunc = (item) => {
    const minRechargeAmount = Number(getStorage("minrecharge"));
    let RechargeAm = Number(pageConst.inRechargeAmnt);
    let UserId = getStorage("uid");

    if (RechargeAm < minRechargeAmount) {
      updateToastDialogState(true, 2, `Minimum recharge amount is ${minRechargeAmount}`);
      return;
    }

    setActiveItem(item.id);

    if (item.id === 'cryptopay') {
      rechargeNow();
    } else if (item.id === 'okpay') {
      const paymentUrl = getStorage("paymentURL") + "/payments/gateways5/initialisation/okpay.php";

      var newForm = document.createElement("form");
      newForm.method = "POST";
      newForm.action = paymentUrl;

      var pastInput = document.createElement("input");
      pastInput.type = "hidden";
      pastInput.name = "order_amount";
      pastInput.value = RechargeAm;

      var authInput = document.createElement("input");
      authInput.type = "hidden";
      authInput.name = "order_userid";
      authInput.value = UserId;

      newForm.appendChild(pastInput);
      newForm.appendChild(authInput);

      document.body.appendChild(newForm);
      newForm.submit();
    } else {
      console.error("Invalid payment gateway");
      return;
    }

    updateToastDialogState(true, 1, "Order Created Successfully!");
  };

  const setAllRechargeOptions = () => {
    let tempData = [];
    const recentBetArr = getStorage("rechargeoptions").split(",");

    for (let i = 0; i < recentBetArr.length; i++) {
      tempData.push(
        <div
          key={i}
          className="pd-10-15 v-center br-5 ft-sz-16 bg-l-blue"
          onClick={() => onInputValChange("rechargeamount", recentBetArr[i])}
        >
          ₹{recentBetArr[i]}
        </div>
      );
    }

    setConstants((previousState) => ({
      ...previousState,
      recordList: tempData,
    }));
  };

  useEffect(() => {
    if (!getStorage("uid")) {
      navigate("/login", { replace: true });
    } else {
      setAllRechargeOptions();
    }
  }, []);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <LoadingDialog intentData={pageConst} />
        <ToastDialog
          intentData={toastDialogConst}
          updateState={updateToastDialogState}
        />
        <div className="zoomAnimView">
          <div className="col-view">
            <div
              className={[styles.walletDetailView, general.bgCustomColor1].join(
                " "
              )}
            >
              <span className="cl-white ft-sz-23">Wallet</span>
              <img
                src={require("../icons/wallet_grey_icon.png")}
                className="h-w-40 mg-t-10"
                alt="icon"
              />
              <span className="cl-white ft-sz-28">
                ₹{Number(getStorage("balance")).toLocaleString()}
              </span>
              <span className="cl-white ft-sz-12">Total Balance</span>
            </div>

            <div className="w-100 pd-0-15 mg-t--40">
              <div className="w-100 col-view pd-10-15 br-10 bg-white bx-shadow-2">
                <div className="col-view">
                <span className="ft-sz-16 ft-wgt-500 cl-l-grey">Select Payment Channel</span>
                <div className="AppRecharge">
                  <style>
                    {`
                      .AppRecharge {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        gap: 1rem;
                        padding: 1rem 0;
                      }
                      .item {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        padding: 1rem;
                        cursor: pointer;
                        border-radius: 0.5rem;
                      }
                      .item.active {
                        background-color: #f0f0f0;
                      }
                    `}
                  </style>
                  {items.map((item) => (
                    <div
                      key={item.id}
                      className={`item ${activeItem === item.id ? 'active' : ''}`}
                      onClick={() => handleOnClickChannel(item)}
                    >
                      <img
                        width="60px"
                        height="19px"
                        src={item.img}
                        className="img"
                        alt={item.name}
                      />
                      <div className="name">{item.name}</div>
                    </div>
                  ))}
                </div>
                  <span className="ft-sz-16 ft-wgt-500 cl-l-grey">Amount</span>
                  <div className="row-view br-b-grey">
                    <span className="cl-l-grey ft-sz-20">Rs</span>
                    <input
                      type="number"
                      className="cutm-inp ft-sz-38 h-60-p inp-ph-l-color"
                      value={pageConst.inRechargeAmnt}
                      placeholder={`${getStorage("minrecharge")} ~ 70000`}
                      onChange={(e) =>
                        onInputValChange("rechargeamount", e.target.value)
                      }
                    />
                  </div>
                </div>

                <div className="w-100 g-v-3 mg-t-25">
                  {pageConst.recordList}
                </div>

                <div
                  className={[
                    general.height45px,
                    general.width100,
                    general.viewCenter,
                    general.colorWhite,
                    general.fontS20Px,
                    general.mgT10px,
                    general.borderRadius,
                    general.bgCustomColor2,
                  ].join(" ")}
                  onClick={() => {
                    const selectedItem = items.find(item => item.id === selectedGateway);
                    if (selectedItem) {
                      rechargeNowFunc(selectedItem);
                    } else {
                      console.error("No gateway selected for deposit.");
                    }
                  }}
                >
                  Deposit
                </div>

                <div className="row-view sb-view a-flex-start mg-t-20">
                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/withdraw"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-drk-black ft-sz-12 mg-t-5">
                      Withdraw
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/rechargerecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/deposit_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="txt-a-center cl-drk-black ft-sz-12 mg-t-5">
                      Deposit<br />History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/withdrawrecords"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/withdrawl_history_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="txt-a-center cl-drk-black ft-sz-12 mg-t-5">
                      Withdraw<br />History
                    </span>
                  </Link>

                  <Link
                    className="txt-deco-n col-view br-10 bg-white"
                    to={"/transactions"}
                  >
                    <div className="txt-a-center">
                      <img
                        src={require("../media/icons/transactions_icon.png")}
                        className="h-w-28"
                        alt="icon"
                      />
                    </div>
                    <span className="cl-drk-black ft-sz-12 mg-t-5">
                      Transactions
                    </span>
                  </Link>
                </div>
              </div>

              <div className="mg-t-13em"></div>
            </div>
          </div>
        </div>

        <BottomNavbar activeBar="recharge" />
      </div>
    </div>
  );
}

export default Deposit;

